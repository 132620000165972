import React from "react"
import './form.css'
import './layout.css'

const Submit = () => (
	<div className="submit">
		<button>Send</button>
	</div>
)

export default Submit









